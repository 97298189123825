import $ from "jquery";
import "../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js";
import * as AOS from "../../assets/libs/aos";
import Footer from "@/components/footer/Footer";
import Header from "@/components/header/header";
import Swal from "sweetalert2";
import Toolbar from "@/components/toolbar/toolbar";
import VueSlickCarousel from 'vue-slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default {
  name: "Home",
  data() {
    return {
      mediaItems: [
        { type: 'image', src: 'path/to/image1.jpg', alt: 'Image 1' },
        { type: 'video', src: 'path/to/video1.mp4' },
        // Add more media items as needed
      ],
      bannerImageTh:{m:require("@/assets/img/banner/banner2024m_th.jpg"),pc:require("@/assets/img/banner/banner2024_th.jpg")},
      bannerImageEn:{m:require("@/assets/img/banner/banner2024m_en.jpg"),pc:require("@/assets/img/banner/banner2024_en.jpg")},
      imageSource: require("@/assets/img/build-color/building-img-color1.png"),
      list_build: [
        {
          id: 1,
          name: this.$t("home.findYourGlass.lowBuilding"),
          img: require("@/assets/img/Home/i-build-sm.png"),
          width_icon: "40",
          type_glass: [],
          type_color: [],
          range_protection: [
            {
              id: 1,
              name: this.$t("home.findYourGlass.properLight"),
              value: 3,
            },
            {
              id: 2,
              name: this.$t("home.findYourGlass.properHeatProtection"),
              value: 4,
            },
          ],
          protection: [
            { id: 1, name: this.$t("home.findYourGlass.safety"), value: true },
            {
              id: 2,
              name: this.$t("home.findYourGlass.UVProtection"),
              value: false,
            },
            {
              id: 3,
              name: this.$t("home.findYourGlass.noiseReducer"),
              value: true,
            },
          ],
        },
        {
          id: 2,
          name: this.$t("home.findYourGlass.highBuilding"),
          img: require("@/assets/img/Home/i-build-lg.png"),
          width_icon: "30",
          type_glass: [],
          type_color: [],
          range_protection: [
            {
              id: 1,
              name: this.$t("home.findYourGlass.properLight"),
              value: 3,
            },
            {
              id: 2,
              name: this.$t("home.findYourGlass.properHeatProtection"),
              value: 4,
            },
          ],
          protection: [
            { id: 1, name: this.$t("home.findYourGlass.safety"), value: true },
            {
              id: 2,
              name: this.$t("home.findYourGlass.UVProtection"),
              value: false,
            },
            {
              id: 3,
              name: this.$t("home.findYourGlass.noiseReducer"),
              value: true,
            },
          ],
        },
      ],
      type_glass: [
        {
          id: 1,
          name: "อาคารขนาดเล็ก",
          list_glass: [
            { id: 1, name: this.$t("home.findYourGlass.sheet") },
            { id: 2, name: this.$t("home.findYourGlass.laminated") },
            { id: 3, name: this.$t("home.findYourGlass.insulated") },
            { id: 4, name: this.$t("home.findYourGlass.laminatedInsulated") },
          ],
        },
        {
          id: 2,
          name: "อาคารขนาดสูง",
          list_glass: [
            { id: 2, name: this.$t("home.findYourGlass.laminated") },
            { id: 3, name: this.$t("home.findYourGlass.insulated") },
            { id: 4, name: this.$t("home.findYourGlass.laminatedInsulated") },
          ],
        },
      ],
      show_glass: [
        { id: 1, name: this.$t("home.findYourGlass.sheet") },
        { id: 2, name: this.$t("home.findYourGlass.laminated") },
        { id: 3, name: this.$t("home.findYourGlass.insulated") },
        { id: 4, name: this.$t("home.findYourGlass.laminatedInsulated") },
      ],
      show_building: [
        {
          name: "อาคารแนวราบขนาดเล็ก/อาคารสูง/ขนาดใหญ่/ขนาดใหญ่พิเศษ",
          img: require("@/assets/img/findyourglass/build/build_1.png"),
        },
        {
          name: "อาคารแนวราบขนาดเล็ก",
          img: require("@/assets/img/findyourglass/build/build_3.png"),
        },
        {
          name: "อาคารสูง/ขนาดใหญ่/ขนาดใหญ่พิเศษ",
          img: require("@/assets/img/findyourglass/build/build_2.png"),
        },
      ],
      type_color: [
        { id: 1, name: this.$t("home.findYourGlass.clear"), color: "white-normal" },
        { id: 2, name: this.$t("home.findYourGlass.green"), color: "green-lime" },
        { id: 3, name: this.$t("home.findYourGlass.darkGrey"), color: "orange-normal" },
        { id: 4, name: this.$t("home.findYourGlass.skyBlue"), color: "sky-normal" },
        { id: 5, name: this.$t("home.findYourGlass.grey"), color: "gray-dark" },
        { id: 6, name: this.$t("home.findYourGlass.bronzeGold"), color: "yellow-light" },
        { id: 7, name: this.$t("home.findYourGlass.other"), color: "black-normal" },
      ],
      range_protection: [
        {
          id: 1,
          name: this.$t("home.findYourGlass.properLight"),
          img: require("@/assets/img/Home/icon/icon-type-sun.png"),
          remark: "Proper Light > 30%",
        },
        {
          id: 2,
          name: this.$t("home.findYourGlass.properHeatProtection"),
          img: require("@/assets/img/Home/icon/icon-type-hot.png"),
          remark: "Proper Heat Protection > 45%",
        },
      ],
      protection: [
        {
          id: 1,
          name: this.$t("home.findYourGlass.safety"),
          img: require("@/assets/img/Home/icon/icon-type-protection.png"),
        },
        {
          id: 2,
          name: this.$t("home.findYourGlass.UVProtection"),
          img: require("@/assets/img/Home/icon/icon-type-uv.png"),
        },
        {
          id: 3,
          name: this.$t("home.findYourGlass.noiseReducer"),
          img: require("@/assets/img/Home/icon/icon-type-sound.png"),
        },
      ],
      list_protection: [
        {
          id: 1,
          name: this.$t("home.findYourGlass.safety"),
          img: require("@/assets/img/findyourglass/i_protection/i_1.png"),
        },
        {
          id: 2,
          name: this.$t("home.findYourGlass.UVProtection"),
          img: require("@/assets/img/findyourglass/i_protection/i_2.png"),
        },
        {
          id: 3,
          name: this.$t("home.findYourGlass.noiseReducer"),
          img: require("@/assets/img/findyourglass/i_protection/i_3.png"),
        },
      ],
      value_of_range_glass: [
        { id: 1, name_display: "<-20", name_show: "<-20%" },
        { id: 2, name_display: "21-30", name_show: "21-30%" },
        { id: 3, name_display: "31-40", name_show: "31-40%" },
        { id: 4, name_display: "41-50", name_show: "41-50%" },
        { id: 5, name_display: "51-60", name_show: "51-60%" },
        { id: 6, name_display: "61->", name_show: "61%->" },
      ],
      list_result_glass: [],
      result: [],
      toggleEnable: false,
      lists_color: [
        {
          id: 1,
          name_color: "gray-dark",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 2,
          name_color: "gray-normal",
          img: require("@/assets/img/Home/example_glass_02.png"),
        },
        {
          id: 3,
          name_color: "gray-light",
          img: require("@/assets/img/Home/example_glass_03.png"),
        },
        {
          id: 4,
          name_color: "white-dark",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 5,
          name_color: "white-normal",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 6,
          name_color: "sky-light",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 7,
          name_color: "green-lime",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 8,
          name_color: "sky-normal",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 9,
          name_color: "orange-light",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 10,
          name_color: "green-light",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 11,
          name_color: "yellow-light",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 12,
          name_color: "orange-normal",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 13,
          name_color: "purple-normal",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 14,
          name_color: "black-normal",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
        {
          id: 15,
          name_color: "snow",
          img: require("@/assets/img/Home/example_glass_01.png"),
        },
      ],
      current_color: {
        id: 1,
        name_color: "gray-dark",
        img: require("@/assets/img/Home/example_glass_01.png"),
      },
      banner_desktop: "",
      banner_full_screen: "",
      banner_mobile: "",
      group_product: [],
      product_content: [],
      product: [],
      desktop_muted: true,
      mobile_muted: true,
      advance: false,
      baseFront: this.$baseFront,
      showModal: false
    };
  },
  watch: {
    async current_color() {
      $("#shade-image-preview").removeClass("aos-animate");
      setTimeout(function () {
        $("#shade-image-preview").addClass("aos-animate");
      }, 200);
    },
  },
  methods: {
    gotoPage(url){
      location.href = url;
  },
    clear_search() {
      this.list_build = [
        {
          id: 1,
          name: this.$t("home.findYourGlass.lowBuilding"),
          img: require("@/assets/img/Home/i-build-sm.png"),
          width_icon: "40",
          type_glass: [],
          type_color: [],
          range_protection: [
            { id: 1, name: this.$t("home.findYourGlass.properLight"), value: 3 },
            { id: 2, name: this.$t("home.findYourGlass.properHeatProtection"), value: 4 },
          ],
          protection: [
            { id: 1, name: this.$t("home.findYourGlass.safety"), value: true },
            { id: 2, name: this.$t("home.findYourGlass.UVProtection"), value: false },
            { id: 3, name: this.$t("home.findYourGlass.noiseReducer"), value: true },
          ],
        },
        {
          id: 2,
          name: this.$t("home.findYourGlass.highBuilding"),
          img: require("@/assets/img/Home/i-build-lg.png"),
          width_icon: "30",
          type_glass: [],
          type_color: [],
          range_protection: [
            { id: 1, name: this.$t("home.findYourGlass.properLight"), value: 3 },
            { id: 2, name: this.$t("home.findYourGlass.properHeatProtection"), value: 4 },
          ],
          protection: [
            { id: 1, name: this.$t("home.findYourGlass.safety"), value: true },
            { id: 2, name: this.$t("home.findYourGlass.UVProtection"), value: false },
            { id: 3, name: this.$t("home.findYourGlass.noiseReducer"), value: true },
          ],
        },
      ];
      this.advance = false;
      this.result = [];
    },
    gotoDetail(id) {
      this.$router.push(`product-content/${id}`);
    },
    async fetchProductContent() {
      await this.$axios
        .get("/api/show/type_product_content")
        .then((response) => {
          if (response.data.code == 200) {
            let product_content = response.data.data.map((i) => {
              return {
                id: i.id,
                ref_type_product_id: i.ref_type_product_id,
                name: i.name,
                text_1: i.banner_text_1,
                text_2: i.banner_text_2,
                detail: i.feature_header
                  ? JSON.parse(i.feature_header)[0].label
                  : null,
                image_vector: i.parameter_feature_list_image_vector
                  ? JSON.parse(i.parameter_feature_list_image_vector)
                  : null,
                product: i.shade_glass ? JSON.parse(i.shade_glass) : null,
                list_product: [],
                current_color: {},
              };
            });
            // this.product_content.fea = this.group_product.product == "" ? [] : JSON.parse(this.group_product.product)
            this.group_product.product.forEach((i) => {
              this.product_content.push(
                product_content.find(
                  (x) => x.ref_type_product_id == i.type_product
                )
              );
            });
            this.product_content.forEach((i) => {
              if (i.product.length > 0) {
                i.product.forEach((x) => {
                  i.list_product.push(
                    this.product.find((j) => j.id == x.ref_product_id)
                  );
                });
                i.current_color = i.list_product[0];
              }
            });
            console.log(this.product_content);
          }
        });
    },
    async fetchHomeGroupProduct() {
      await this.$axios.get("/api/show/home_group_product").then((response) => {
        if (response.data.code == 200) {
          this.group_product = response.data.data[0];
          this.group_product.product =
            this.group_product.product == ""
              ? []
              : JSON.parse(this.group_product.product);
          console.log(this.group_product);
        }
      });
    },
    async fetchHomeBanner() {
      await this.$axios.get("/api/show/home_banner").then((response) => {
        if (response.data.code == 200) {
          this.banner_desktop =
            this.baseFront +
            JSON.parse(response.data.data[0].parameter_banner)[0].image;
          this.banner_full_screen =
            this.baseFront +
            JSON.parse(response.data.data[0].parameter_banner)[0].full_screen;
          this.banner_mobile =
            this.baseFront +
            JSON.parse(response.data.data[0].parameter_banner)[0].image_mobile;
        }
      });
    },
    gotoCompare(item) {
      this.$router.push({ path: "/compare", query: { current_1: item.id } });
    },
    openLink(url) {
      console.log(url);
      window.open(url, "_blank");
    },
    async fetchTypeProduct() {
      await this.$axios.get("/api/show/type_product").then((response) => {
        if (response.data.code == 200) {
          this.type_product = response.data.data;
        }
      });
    },
    async fetchProduct() {
      await this.$axios.get("/api/show/product").then((response) => {
        if (response.data.code == 200) {
          this.product = response.data.data;
          this.list_result_glass = response.data.data;
        }
      });
    },
    toggleButton(id) {
      if (id == 1) {
        document
          .getElementById("find-your-glass-open-2")
          .classList.remove("show");
      } else {
        document
          .getElementById("find-your-glass-open-1")
          .classList.remove("show");
      }
      this.$refs["FYGBtn" + id][0].focus();
    },
    CloseCollapse(id) {
      this.$refs["FYG" + id][0].focus();
    },
    search_glass(data) {
      this.$router.push({
        path: "/findyourglass",
        query: { findyourglass: data, advance: this.advance },
      });
      // if (data.id == 1) {
      //   this.result = [];
      //   let filter_type_glass = [];
      //   let filter_type_color = [];
      //   let filter_vlt = [];
      //   let filter_shgc = [];
      //   let protect_1 = data.protection[0].value ? 1 : 0;
      //   let protect_2 = data.protection[1].value ? 1 : 0;
      //   let protect_3 = data.protection[2].value ? 1 : 0;
      //   // ค้นหาประเภทกระจก
      //   if (data.type_glass.length > 0) {
      //     filter_type_glass = this.list_result_glass.filter((i) =>
      //       data.type_glass.includes(i.type_glass)
      //     );
      //   } else {
      //     filter_type_glass = this.list_result_glass;
      //   }
      //   // ค้นหาโทนสี
      //   if (data.type_color.length > 0) {
      //     filter_type_color = filter_type_glass.filter((i) => {
      //       if (JSON.parse(i.type_color).length == 1) {
      //         return data.type_color.includes(
      //           JSON.parse(i.type_color)[0].color
      //         );
      //       } else if (JSON.parse(i.type_color).length > 1) {
      //         return (
      //           data.type_color.includes(JSON.parse(i.type_color)[0].color) ||
      //           data.type_color.includes(JSON.parse(i.type_color)[1].color)
      //         );
      //       }
      //     });
      //   } else {
      //     filter_type_color = filter_type_glass;
      //   }
      //   if (this.advance) {
      //     filter_vlt = filter_type_color.filter((i) => {
      //       if (data.range_protection[0].value == 1) {
      //         return parseFloat(i.vlt) <= 20;
      //       } else if (data.range_protection[0].value == 2) {
      //         return parseFloat(i.vlt) >= 21 && parseFloat(i.vlt) <= 30;
      //       } else if (data.range_protection[0].value == 3) {
      //         return parseFloat(i.vlt) >= 31 && parseFloat(i.vlt) <= 40;
      //       } else if (data.range_protection[0].value == 4) {
      //         return parseFloat(i.vlt) >= 41 && parseFloat(i.vlt) <= 50;
      //       } else if (data.range_protection[0].value == 5) {
      //         return parseFloat(i.vlt) >= 51 && parseFloat(i.vlt) <= 60;
      //       } else if (data.range_protection[0].value == 6) {
      //         return parseFloat(i.vlt) >= 61;
      //       }
      //     });

      //     // ค้นหาระดับการป้องกันความร้อน
      //     filter_shgc = filter_vlt.filter((i) => {
      //       if (data.range_protection[1].value == 1) {
      //         return parseFloat(i.shgc) <= 20;
      //       } else if (data.range_protection[1].value == 2) {
      //         return parseFloat(i.shgc) >= 21 && parseFloat(i.shgc) <= 30;
      //       } else if (data.range_protection[1].value == 3) {
      //         return parseFloat(i.shgc) >= 31 && parseFloat(i.shgc) <= 40;
      //       } else if (data.range_protection[1].value == 4) {
      //         return parseFloat(i.shgc) >= 41 && parseFloat(i.shgc) <= 50;
      //       } else if (data.range_protection[1].value == 5) {
      //         return parseFloat(i.shgc) >= 51 && parseFloat(i.shgc) <= 60;
      //       } else if (data.range_protection[1].value == 6) {
      //         return parseFloat(i.shgc) >= 61;
      //       }
      //     });

      //     // ค้นหาความปลอดภัย
      //     this.result = filter_shgc.filter(
      //       (i) =>
      //         i.protect_1 == protect_1 &&
      //         i.protect_2 == protect_2 &&
      //         i.protect_3 == protect_3 &&
      //         (i.type_build == 2 || i.type_build == 1)
      //     );
      //   } else {
      //     this.result = filter_type_color;
      //   }
      //   //ค้นหาระดับแสงส่องผ่านกระจก

      //   if (this.result.length == 0) {
      //     Swal.fire({
      //       icon: "error",
      //       title: "ผลการค้นหา",
      //       text: "ไม่พบข้อมูลกระจกที่คุณค้นหา",
      //     });
      //   }
      // } else if (data.id == 2) {
      //   this.result = [];
      //   let filter_type_glass = [];
      //   let filter_type_color = [];
      //   let filter_vlt = [];
      //   let filter_shgc = [];
      //   let protect_1 = data.protection[0].value ? 1 : 0;
      //   let protect_2 = data.protection[1].value ? 1 : 0;
      //   let protect_3 = data.protection[2].value ? 1 : 0;
      //   // ค้นหาประเภทกระจก
      //   if (data.type_glass.length > 0) {
      //     filter_type_glass = this.list_result_glass.filter((i) =>
      //       data.type_glass.includes(i.type_glass)
      //     );
      //   } else {
      //     filter_type_glass = this.list_result_glass;
      //   }
      //   // ค้นหาโทนสี
      //   if (data.type_color.length > 0) {
      //     filter_type_color = filter_type_glass.filter((i) => {
      //       if (JSON.parse(i.type_color).length == 1) {
      //         return data.type_color.includes(
      //           JSON.parse(i.type_color)[0].color
      //         );
      //       } else if (JSON.parse(i.type_color).length > 1) {
      //         return (
      //           data.type_color.includes(JSON.parse(i.type_color)[0].color) ||
      //           data.type_color.includes(JSON.parse(i.type_color)[1].color)
      //         );
      //       }
      //     });
      //   } else {
      //     filter_type_color = filter_type_glass;
      //   }
      //   //ค้นหาระดับแสงส่องผ่านกระจก
      //   if (this.advance) {
      //     filter_vlt = filter_type_color.filter((i) => {
      //       if (data.range_protection[0].value == 1) {
      //         return parseFloat(i.vlt) <= 20;
      //       } else if (data.range_protection[0].value == 2) {
      //         return parseFloat(i.vlt) >= 21 && parseFloat(i.vlt) <= 30;
      //       } else if (data.range_protection[0].value == 3) {
      //         return parseFloat(i.vlt) >= 31 && parseFloat(i.vlt) <= 40;
      //       } else if (data.range_protection[0].value == 4) {
      //         return parseFloat(i.vlt) >= 41 && parseFloat(i.vlt) <= 50;
      //       } else if (data.range_protection[0].value == 5) {
      //         return parseFloat(i.vlt) >= 51 && parseFloat(i.vlt) <= 60;
      //       } else if (data.range_protection[0].value == 6) {
      //         return parseFloat(i.vlt) >= 61;
      //       }
      //     });

      //     // ค้นหาระดับการป้องกันความร้อน
      //     filter_shgc = filter_vlt.filter((i) => {
      //       if (data.range_protection[1].value == 1) {
      //         return parseFloat(i.shgc) <= 20;
      //       } else if (data.range_protection[1].value == 2) {
      //         return parseFloat(i.shgc) >= 21 && parseFloat(i.shgc) <= 30;
      //       } else if (data.range_protection[1].value == 3) {
      //         return parseFloat(i.shgc) >= 31 && parseFloat(i.shgc) <= 40;
      //       } else if (data.range_protection[1].value == 4) {
      //         return parseFloat(i.shgc) >= 41 && parseFloat(i.shgc) <= 50;
      //       } else if (data.range_protection[1].value == 5) {
      //         return parseFloat(i.shgc) >= 51 && parseFloat(i.shgc) <= 60;
      //       } else if (data.range_protection[1].value == 6) {
      //         return parseFloat(i.shgc) >= 61;
      //       }
      //     });

      //     // ค้นหาความปลอดภัย
      //     this.result = filter_shgc.filter(
      //       (i) =>
      //         i.protect_1 == protect_1 &&
      //         i.protect_2 == protect_2 &&
      //         i.protect_3 == protect_3 &&
      //         (i.type_build == 3 || i.type_build == 1)
      //     );
      //     console.log(this.result);
      //   } else {
      //     this.result = filter_type_color;
      //   }

      //   if (this.result.length == 0) {
      //     Swal.fire({
      //       icon: "error",
      //       title: "ผลการค้นหา",
      //       text: "ไม่พบข้อมูลกระจกที่คุณค้นหา",
      //     });
      //   }
      // }
    },
    search_glass_all(data) {
      if (data.id == 1) {
        this.result = [];
        // ค้นหาความปลอดภัย
        this.result = this.list_result_glass.filter(
          (i) => i.type_build == 2 || i.type_build == 1
        );
      } else if (data.id == 2) {
        this.result = [];
        // ค้นหาความปลอดภัย
        this.result = this.list_result_glass.filter(
          (i) => i.type_build == 3 || i.type_build == 1
        );
      }
      console.log(this.result);
    },
    toggleFullScreen() {
      // Check if the video is in full screen mode using the different fullscreen functions
      this.desktop_muted = false;
      // If the video is not in full screen mode, enter full screen mode
      if (this.video.requestFullscreen) {
        this.video.src = this.banner_full_screen;
        this.video.requestFullscreen();
      } else if (this.video.webkitRequestFullscreen) {
        this.video.src = this.banner_full_screen;
        this.video.webkitRequestFullscreen();
      } else if (this.video.mozRequestFullScreen) {
        this.video.src = this.banner_full_screen;
        this.video.mozRequestFullScreen();
      } else if (this.video.msRequestFullscreen) {
        this.video.src = this.banner_full_screen;
        this.video.msRequestFullscreen();
      }
    },
    toggleFullScreenMobile() {
      // Check if the video is in full screen mode using the different fullscreen functions
      this.mobile_muted = false;
      // If the video is not in full screen mode, enter full screen mode
      if (this.videoMobile.requestFullscreen) {
        this.videoMobile.src = this.banner_full_screen;
        this.videoMobile.requestFullscreen();
      } else if (this.videoMobile.webkitRequestFullscreen) {
        this.videoMobile.src = this.banner_full_screen;
        this.videoMobile.webkitRequestFullscreen();
      } else if (this.videoMobile.mozRequestFullScreen) {
        this.videoMobile.src = this.banner_full_screen;
        this.videoMobile.mozRequestFullScreen();
      } else if (this.videoMobile.msRequestFullscreen) {
        this.videoMobile.src = this.banner_full_screen;
        this.videoMobile.msRequestFullscreen();
      } else {
        this.videoMobile.src = this.banner_full_screen;
        window.open(this.videoMobile.src, this.banner_full_screen);
      }
      // For mobile devices, open the video in a new window
    },
  },
  async mounted() {
    this.showModal = true;
    await this.fetchHomeBanner();
    await this.fetchTypeProduct();
    await this.fetchProduct();
    await this.fetchHomeGroupProduct();
    await this.fetchProductContent();
    document.title = "AGC - Home";
    AOS.init();
    // eslint-disable-next-line
    // $('#MapModal').modal('show');
    // $('#MapModal').modal('show');
    // this.$refs.myButton.click();
    $(".m-search-BT").on("click", (evt) => {
      $(".m-search-BT + .dp").toggle();
    });
    $('img[alt="color"]').on("click", (evt) => {
      $('img[alt="color"].active').removeClass("active");
      $("#" + evt.target.id).addClass("active");
      // $('img[alt="building-img"]').attr('src',`/img/build-color/building-img-${evt.target.id}.png`);
      this.imageSource = require(`@/assets/img/build-color/building-img-${evt.target.id}.png`);
    });
    // Get a reference to the video element
    this.video = this.$refs.myVideo;
    this.videoMobile = this.$refs.myVideoMobile;
    $("video").bind(
      "webkitfullscreenchange mozfullscreenchange fullscreenchange",
      (e) => {
        var state =
          document.fullScreen ||
          document.mozFullScreen ||
          document.webkitIsFullScreen;
        // Now do something interesting
        if (!state) {
          this.video.src = this.banner_desktop;
          this.videoMobile.src = this.banner_mobile;
        }
      }
    );
  },
  components: {
    Footer,
    Header,
    Toolbar,
    VueSlickCarousel,
  },
};
